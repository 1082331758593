<i18n locale="th" lang="yaml" >
page.title : "{name} :: ใบซ่อมใหม่ (ลูกค้านำเข้าศูนย์)"
page.description : "เปิดงานซ่อมสำหรับ Drone ที่ลูกค้านำเข้าศูนย์"

service_create.confirm.title : "ยืนยันการเปิดงานซ่อม"
service_create.confirm.message : "คุณต้องการยืนยันการเปิดงานซ่อมตามนี้ ?"
service_create.success : "เปิดงานซ่อมเรียบร้อยด้วย เลขอ้างอิง {service}"
service_create.submit.label : "เปิดงานซ่อม"
</i18n>

<template>
	<div id="create_service_page" class="page">
		<my-page-header
			:title="$t('page.title',{ name: drone.serial_no })"
			:description="$t('page.description')" />
		<a-card :bordered="false">
			<ServiceForm
				ref="formRef"
				:loading="loading"
				mode="create"
				@submit="handleSubmit">
				<template v-slot:submitSlot="{ formLoading }">
					<a-button
						:disabled="formLoading"
						icon="left"
						size="large"
						:style="{ 'margin-right': '4px' }"
						@click="goBack">
						{{$t('common.back')}}
					</a-button>
					<a-button
						:loading="formLoading"
						type="info"
						html-type="submit"
						class="btn-submit"
						icon="edit"
						size="large">
						{{$t('service_create.submit.label')}}
					</a-button>
				</template>
			</ServiceForm>
		</a-card>
	</div>
</template>

<script>
import axios from "axios"
import PageMixin from "@mixins/PageMixin.vue"
import ServiceForm from "@components/service/ServiceForm.vue"
import {emptyDrone} from "@utils/objectUtil"

export default {
	components : {
		ServiceForm,
	} ,
	mixins : [PageMixin] ,
	page() {
		return {
			title : this.$t('page.title',{name: this.drone.serial_no})
		}
	} ,
	props : {
		droneData : {
			type : null,
			default : emptyDrone()
		}
	} ,
	data() {
		return {
			loading : false,
		}
	} ,
	computed : {
		drone() {
			return this.droneData.drone ? this.droneData.drone : {}
		}
	} ,
	watch : {
		droneData : {
			handler() {
				this.preProcess()
			} ,
			deep: true
		}
	} ,
	mounted(){
		this.preProcess()
	} ,
	methods : {
		preProcess() {
			if (!this.$authorize('create','service')) {
				this.$pageError({name : 'error_forbidden'})
				return
			}
			this.$nextTick(()=>{
				this.$refs.formRef.updateDroneData(this.droneData)
			})

		} ,
		goBack() {
			if (this.$route.query.from_create) {
				return this.$open({name : 'service/create'})
			} else {
				return this.$open({name : 'drone/view',params : {serial_no : this.drone.serial_no}})
			}
		} ,
		handleSubmit(formData) {
			this.$confirm({
				title : this.$t('service_create.confirm.title') ,
				content : this.$t('service_create.confirm.message') ,
				maskClosable : true,
				onOk: () => {
					this.loading = true

					axios.post('/api/services/create',formData).then((response)=>{
						const service = response.data.data.service;

						this.$message.success(this.$t('service_create.success',{"service" : service.service_no}))
						this.$router.push({name:'service/view',params: {id : service.id}})
					}).catch((error) => {
						this.$refs.formRef.formErrors(error)
					}).finally(()=> {
						this.loading = false
					})
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>

</style>
